import { template as template_27718d38c00d41dc997f0269559a2b06 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_27718d38c00d41dc997f0269559a2b06(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;

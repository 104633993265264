import { template as template_8b19c8d057bc4caa9d0dff7cd7c41ce6 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const SidebarSectionHeader = template_8b19c8d057bc4caa9d0dff7cd7c41ce6(`
  {{#if @collapsable}}
    <DButton
      @title="sidebar.toggle_section"
      @action={{@toggleSectionDisplay}}
      @forwardEvent={{true}}
      aria-controls={{@sidebarSectionContentId}}
      aria-expanded={{if @isExpanded "true" "false"}}
      class="sidebar-section-header sidebar-section-header-collapsable btn-transparent"
    >
      {{yield}}
    </DButton>
  {{else}}
    <span class="sidebar-section-header">
      {{yield}}
    </span>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionHeader;

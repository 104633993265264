import { template as template_79c2b75806c446179933c09a4c46251b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_79c2b75806c446179933c09a4c46251b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;

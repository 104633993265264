import { template as template_e8a235f1222f4909a79c9ca55d3b333b } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_e8a235f1222f4909a79c9ca55d3b333b(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;

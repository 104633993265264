import { template as template_14fec92e09da47bb90ce87e23b07459b } from "@ember/template-compiler";
const FKLabel = template_14fec92e09da47bb90ce87e23b07459b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

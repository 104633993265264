import { template as template_59aeb3cf1b3e4204b98440f2fde39ac5 } from "@ember/template-compiler";
const FKText = template_59aeb3cf1b3e4204b98440f2fde39ac5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
